import { useState, useEffect, useMemo } from "react";
import { Widget } from "./Widget";
import { nanoid } from "nanoid";
import axios from "axios";


export const WidgetContainer = ({license = "", greeting = ""}) => {

    const [messages, setMessages] = useState([]);
    const [responseMessage, setResponseMessage] = useState([])
    const [indicator,setindicator] = useState(false)

    useEffect( () => {
        if ( greeting && messages.length === 0 ) {
            setMessages(messages.concat({
                _id: nanoid(),
                message: greeting,
                sender: "remote",
                direction: "incoming",
            }));
        }
    },[greeting, messages]);

    const remoteName = useMemo( () => {
        if ( license === "123" ) {
            return "HomeWorkHero";
        } else if (license === "456" ) {
            return "HyugaaStorm";
        } else if (license === "789" ) {
            return "Ganesh";
        }
    },[license]);
    
    useEffect(()=>{
        setMessages(messages.concat(responseMessage))
    },[responseMessage])

    const handleSend = (message) => {
        setindicator(true)
        const newMessages = [
            {
                _id: nanoid(),
                message,
                sender: "me",
                direction: "outgoing",
                position: "single",
            },
          
        ];
        setMessages(messages.concat(newMessages));



        axios({
            url:`https://botnew.brainstormer.io/widget_handler`,
            method: 'post',
            data: {
                "query":message,
                "bot_id":"bot_abb82836_bf04_4dd6_9fc1_b16d11e68a5f",
            },
            headers: {
                'Api-token': 'BLiEUe64EC4Wj7HPYPXa'
            },
        })
        //refresh component here and remove then
        .then((res) => res)
        .then((response) => {
            const newMessages2 = [
                {
                    _id: nanoid(),
                    message: `${response.data.message}`,
                    sender: "remote",
                    direction: "incoming",
                    position: "single",
                }
            ];
            setResponseMessage(newMessages2)
            setindicator(false)
           // setMessages(messages.concat(newMessages2));
           console.log(response)
        })
        .catch((err) => {
          console.log(err)
         });
       
    };

    return <Widget remoteName={remoteName} messages={messages} onSend={handleSend} typingInd={indicator} />

};
